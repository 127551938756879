import { QueueFlowData } from '@voicefoundry-cloud/vf-omp-shared';
import { ContactFlowSummary, PromptSummary } from '@aws-sdk/client-connect';

// These interfaces are shared by multiple contexts

export enum ENTITY_TYPES {
  FLOWS = 'flows',
  CUSTOMER_QUEUE_FLOWS = 'customerQueueFlows',
  QUEUES = 'queues',
  PROMPTS = 'prompts',
  NUMBERS = 'numbers',
}

export interface HasEntity {
  getEntityAttrValue: (type: ENTITY_TYPES, keyType: string, keyValue: string, returnAttr?: string) => any;
}

export interface HasFlows {
  flowsLoaded: boolean;
  flows: ContactFlowSummary[];
  customerQueueFlows: ContactFlowSummary[];
}

export interface HasPrompts {
  promptsLoaded: boolean;
  prompts: PromptSummary[];
}

export interface HasQueueTreatment {
  queueTreatmentLoaded: boolean;
  queueTreatmentList: QueueFlowData[];
}

/**
 * Common implementation to reduce code duplication
 */
export const getEntityAttrValueHelper = (
  state: Record<string, any>,
  type: ENTITY_TYPES,
  keyType: string,
  keyValue: string,
  returnAttr?: string
): any => {
  const item = state[type].find(o => o[keyType] === keyValue);
  if (!item) return '';
  return returnAttr ? item[returnAttr] : item;
};
