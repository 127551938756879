import { OmpPolicyConstructorType } from './aggregatePermissionTypes';

export type OmpCtrManagementService = 'ctr-management';

export type CtrManagementActions =
  | `${OmpCtrManagementService}:DeleteCtrs`
  | `${OmpCtrManagementService}:LockCtrs`
  | `${OmpCtrManagementService}:AudioPlayback`
  | `${OmpCtrManagementService}:ListCtrs`;

export type OmpCtrManagementPolicyType = OmpPolicyConstructorType<'Allow', CtrManagementActions, undefined>;

export const ctrActionList: CtrManagementActions[] = [
  'ctr-management:AudioPlayback',
  'ctr-management:DeleteCtrs',
  'ctr-management:ListCtrs',
  'ctr-management:LockCtrs',
];
