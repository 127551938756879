import React, { createContext, useEffect, useState } from 'react';

import {
  IUserManagementApi,
  HelperApi,
  IConnectApi,
  HolidayApi,
  ClosureApi,
  HourApi,
  IConfigSetsApi,
  ISyncApi,
  IMenuManagementApi,
  ILexApi,
} from '@voicefoundry-cloud/vf-omp-shared';
import { ClosuresApiClient } from './ClosuresApiClient';
import { ConnectApiClient } from './ConnectApiClient';
import { ConfigSetApiClient } from './ConfigSetApiClient';
import { EntryPointManagementApiClient } from './ContactEntryPointsApiClient';
import { HelpersApiClient } from './HelpersApiClient';
import { HolidaysApiClient } from './HolidaysApiClient';
import AmplifyService from '@vf/services/AmplifyService';
import { UserManagementApiClient } from './UserManagementApiClient';
import { MenuManagementApiClient } from './MenuManagementApiClient';
import { QueueTreatmentApiClient } from './QueueTreatmentApiClient';
import { IPhoneNumberTaxonomyApiClient, PhoneNumberTaxonomyApiClient } from './PhoneNumberTaxonomyApiClient';
import { SyncApiClient } from './SyncApiClient';
import Loader from 'shared/components/Loader';
import { LexApiClient } from './LexApiClient';
import { HoursApiClient } from './HoursApiClient';
import { useAppContext } from '../ContextProvider';
import { ExtensionDialingApiClient } from './ExtensionDialingApiClient';
import { CtrManagementApiClient } from './CtrManagementApiClient';
import { PermissionsV2ApiClient } from './PermissionsV2ApiClient';
import { ADMIN_GROUP } from 'shared/constants/AppConst';

export interface IApiContext {
  configSet: IConfigSetsApi;
  closure: ClosureApi;
  connect: IConnectApi;
  helpers: HelperApi;
  holiday: HolidayApi;
  hour: HourApi;
  sync: ISyncApi;
  menu: IMenuManagementApi;
  entryPoint: EntryPointManagementApiClient;
  queueTreatment: QueueTreatmentApiClient;
  userManagement: IUserManagementApi;
  lex: ILexApi;
  phoneNumberTaxonomy: IPhoneNumberTaxonomyApiClient;
  extensionDialing: ExtensionDialingApiClient;
  ctrManagement: CtrManagementApiClient;
  permissionsV2: PermissionsV2ApiClient;
}

export const ApiContext = createContext<IApiContext>(null as any);

function ApiContextProvider(props: { children: any }) {
  const { config, user, featureList, updateAuthUser, enableTenancy, setTenancySetting, setAvailableFeatures } =
    useAppContext();
  const isApiV2 = featureList.includes('apiV2'); // permsV2 supported
  console.log('ApiContextProvider', JSON.stringify({ featureList, isApiV2 }));
  const [state, setState] = useState<IApiContext>(null as any);
  useEffect(() => {
    if (config) {
      const useAuth = config.environment !== 'local';
      const dynamicUrl = `${config.apiUrl}/api${isApiV2 ? '/v2/' : '/'}`;
      setState({
        configSet: new ConfigSetApiClient(dynamicUrl, useAuth), // permsV2 supported
        hour: new HoursApiClient(dynamicUrl, useAuth), // permsV2 supported
        closure: new ClosuresApiClient(dynamicUrl, useAuth), // permsV2 supported
        connect: new ConnectApiClient(config.apiUrl, useAuth, isApiV2), // partially permsV2 supported
        holiday: new HolidaysApiClient(dynamicUrl, useAuth), // permsV2 supported
        ctrManagement: new CtrManagementApiClient(config.apiUrl, useAuth), // permsV2 supported
        helpers: new HelpersApiClient(config.apiUrl, useAuth),
        sync: new SyncApiClient(config.apiUrl, useAuth),
        menu: new MenuManagementApiClient(config.apiUrl, useAuth),
        entryPoint: new EntryPointManagementApiClient(config.apiUrl, useAuth),
        queueTreatment: new QueueTreatmentApiClient(config.apiUrl, useAuth),
        userManagement: new UserManagementApiClient(config.apiUrl, useAuth),
        lex: new LexApiClient(config.apiUrl, useAuth),
        phoneNumberTaxonomy: new PhoneNumberTaxonomyApiClient(config.apiUrl, useAuth),
        extensionDialing: new ExtensionDialingApiClient(config.apiUrl, useAuth),
        permissionsV2: new PermissionsV2ApiClient(config.apiUrl, useAuth),
      });
      if (!config.cognito) return;

      AmplifyService.setHubListener(updateAuthUser, enableTenancy, setTenancySetting, setAvailableFeatures);

      AmplifyService.isAuthenticated().then(bool => {
        if (bool) {
          AmplifyService.getClaims()
            .then(claims => {
              console.log(`ApiContextProvider AmplifyService.getClaims() claims: ${JSON.stringify(claims)}`);
              if (!claims) {
                AmplifyService.federatedLogin();
              } else {
                if (claims.groups.includes(ADMIN_GROUP) || isApiV2) {
                  if (claims.tenancyEnabled) {
                    claims.groups.push('tenancyManagement');
                    enableTenancy(true);
                    if (claims.tenancyOn) {
                      setTenancySetting(true);
                    }
                  }
                  setAvailableFeatures(claims.features);
                  updateAuthUser({
                    username: claims.username,
                    userType: 'admin',
                    groups: claims.groups.concat(claims.features),
                    email: claims.email,
                  });
                } else {
                  if (claims.tenancyOn) {
                    setTenancySetting(true);
                  }
                  setAvailableFeatures(claims.features);
                  updateAuthUser({
                    username: claims.username,
                    userType: 'user',
                    groups: claims.groups.concat(claims.features),
                    email: claims.email,
                  });
                }
              }
            })
            .catch(err => {
              console.log(err);
              updateAuthUser(null);
            });
        } else {
          AmplifyService.federatedLogin();
        }
      });
    }
  }, [config, isApiV2, updateAuthUser]);

  return <ApiContext.Provider value={state}>{user && state ? props.children : <Loader />}</ApiContext.Provider>;
}

export default ApiContextProvider;
