import { getLogger } from '@vf/services/LoggingService';
import {
  ContactFlowSummary,
  HierarchyGroup,
  HierarchyGroupSummary,
  HierarchyStructure,
  PhoneNumberSummary,
  QueueSummary,
  RoutingProfileSummary,
  SecurityProfileSummary,
  User,
  UserSummary,
} from '@aws-sdk/client-connect';
import {
  AggregatedAgentMetricsList,
  ConnectPrompt,
  IConnectApi,
  IAggregatedMetrics,
  IContactSearchQuery,
  IContactSearchQueryId,
  IContactSearchQueryResponse,
  IGetSignedUrlRequest,
  IGetSignedUrlResponse,
  IStartContactSearchQueryResponse,
} from '@voicefoundry-cloud/vf-omp-shared';
import { ApiClientBase } from './ApiClientBase';

export class ConnectApiClient extends ApiClientBase implements IConnectApi {
  constructor(
    apiUrl: string,
    useAuth: boolean,
    private isApiV2: boolean
  ) {
    super(apiUrl, getLogger('ConnectApiClient'), useAuth);
  }
  // V2 Supported endpoints:
  // '/api/v2/connect/listUsersDetailed': ['GET'], -- CHECK
  // '/api/v2/connect/listUsers': ['GET'], -- CHECK
  // '/api/v2/connect/listUserHierarchyGroupsSummary': ['GET'], -- CHECK
  // '/api/v2/connect/listUserHierarchyGroupsDetailed': ['GET'], -- CHECK
  // '/api/v2/connect/listRoutingProfiles': ['GET'], -- CHECK
  // '/api/v2/connect/describeUserHierarchyGroupsStructure': ['GET'], -- CHECK
  // '/api/v2/connect/listPrompts': ['GET'], -- CHECK
  // '/api/v2/connect/listQueues': ['GET'], -- CHECK
  // '/api/v2/connect/listNumbers': ['GET'], -- CHECK
  // '/api/v2/connect/listContactFlows': ['GET'], -- CHECK

  getVersionPrefix() {
    return this.isApiV2 ? '/api/v2/' : '/api/';
  }

  async listPrompts(): Promise<ConnectPrompt[]> {
    const result = await this.get(`${this.getVersionPrefix()}connect/listPrompts`);
    return (await result.json()).sort((a, b) => a.Name.localeCompare(b.Name));
  }

  async listQueues(): Promise<QueueSummary[]> {
    const result = await this.get(`${this.getVersionPrefix()}connect/listQueues`);
    return (await result.json()).sort((a, b) => a.Name.localeCompare(b.Name));
  }
  async listRoutingProfiles(withQueues?: boolean): Promise<RoutingProfileSummary[]> {
    const result = !withQueues
      ? await this.get(`${this.getVersionPrefix()}connect/listRoutingProfiles`)
      : await this.get(`${this.getVersionPrefix()}connect/listRoutingProfiles?withQueues=true`);
    return result.json();
  }
  async listUsers(): Promise<UserSummary[]> {
    const result = await this.get(`${this.getVersionPrefix()}connect/listUsers`);
    return (await result.json()).sort((a, b) => a.Username.localeCompare(b.Username));
  }
  async listUsersDetailed(): Promise<User[]> {
    const result = await this.get(`${this.getVersionPrefix()}connect/listUsersDetailed`);
    return result.json();
  }
  async listContactFlows(): Promise<ContactFlowSummary[]> {
    const result = await this.get(`${this.getVersionPrefix()}connect/listContactFlows`);
    return (await result.json()).sort((a, b) => a.Name.localeCompare(b.Name));
  }
  async listNumbers(): Promise<PhoneNumberSummary[]> {
    const result = await this.get(`${this.getVersionPrefix()}connect/listNumbers`);
    return result.json();
  }
  async listSecurityProfiles(): Promise<SecurityProfileSummary[]> {
    const result = await this.get('/api/connect/listSecurityProfiles');
    return result.json();
  }
  async listUserHierarchyGroupsSummary(): Promise<HierarchyGroupSummary[]> {
    const result = await this.get(`${this.getVersionPrefix()}connect/listUserHierarchyGroupsSummary`);
    return result.json();
  }
  async listUserHierarchyGroupsDetailed(): Promise<HierarchyGroup[]> {
    const result = await this.get(`${this.getVersionPrefix()}connect/listUserHierarchyGroupsDetailed`);
    return result.json();
  }
  async getUserHierarchyDetail(hierarchyId: string): Promise<HierarchyGroup> {
    const result = await this.get(`/api/connect/getHierarchyGroupDetail/${hierarchyId}`);
    return result.json();
  }
  async describeUserHierarchyGroupsStructure(): Promise<HierarchyStructure> {
    const result = await this.get(`${this.getVersionPrefix()}connect/describeUserHierarchyGroupsStructure`);
    return result.json();
  }
  async updateUserRoutingProfile(userId: string, routingProfileId: string): Promise<void> {
    await this.post(`/api/connect/updateUserRoutingProfile?userId=${userId}&routingProfileId=${routingProfileId}`);
  }
  async getCurrentQueueMetricData(): Promise<IAggregatedMetrics> {
    const result = await this.get('/api/connect/getCurrentMetricData/queues');
    return result.json();
  }
  async getCurrentAgentMetricData(): Promise<AggregatedAgentMetricsList> {
    const result = await this.get('/api/connect/getCurrentMetricData/agents');
    return result.json();
  }

  async getCurrentRoutingProfileMetricData(): Promise<IAggregatedMetrics> {
    const result = await this.get('/api/connect/getCurrentMetricData/routing-profiles');
    return result.json();
  }
  // async getHistoricalQueueMetricData(
  //   startDate: string,
  //   endDate: string,
  // ): Promise<VFGetHistoricalMetricDataResponse> {
  //   const result = await this.get(
  //     `connect/getMetricData/queues?startDate=${startDate}&endDate=${endDate}`,
  //   );
  //   return result.json();
  // }
  async startContactSearchQuery(query: IContactSearchQuery): Promise<IStartContactSearchQueryResponse> {
    const result = await this.post('/api/connect/contact-search/query', query);
    return result.json();
  }
  async getContactSearchResults(queryId: IContactSearchQueryId): Promise<IContactSearchQueryResponse> {
    const result = await this.get(`/api/connect/contact-search/query/${queryId}`);
    return result.json();
  }

  async getRecordingSignedUrl(data: IGetSignedUrlRequest): Promise<IGetSignedUrlResponse> {
    const result = await this.post(`/api/connect/contact-search/get-recording-signed-url`, data);
    return result.json();
  }
  async startContactRecordDetailQuery(contactId: string): Promise<IStartContactSearchQueryResponse> {
    const result = await this.get(`/api/connect/contact-search/contact-trace-records/details/${contactId}`);
    return result.json();
  }
  async startContactAttributesQuery(contactId: string): Promise<IStartContactSearchQueryResponse> {
    const result = await this.get(`/api/connect/contact-search/contact-trace-records/attributes/${contactId}`);
    return result.json();
  }
}
