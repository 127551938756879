export interface Language {
  name: string;
  code: string;
}

export const languages: Language[] = [
  { name: 'Afrikaans', code: 'af' },
  { name: 'Afrikaans, South Africa', code: 'af-ZA' },
  { name: 'Albanian', code: 'sq' },
  { name: 'Albanian, Albania', code: 'sq-AL' },
  { name: 'Arabic', code: 'ar' },
  { name: 'Arabic, Algeria', code: 'ar-DZ' },
  { name: 'Arabic, Bahrain', code: 'ar-BH' },
  { name: 'Arabic, Egypt', code: 'ar-EG' },
  { name: 'Arabic, Iraq', code: 'ar-IQ' },
  { name: 'Arabic, Jordan', code: 'ar-JO' },
  { name: 'Arabic, Kuwait', code: 'ar-KW' },
  { name: 'Arabic, Lebanon', code: 'ar-LB' },
  { name: 'Arabic, Libya', code: 'ar-LY' },
  { name: 'Arabic, Morocco', code: 'ar-MA' },
  { name: 'Arabic, Oman', code: 'ar-OM' },
  { name: 'Arabic, Qatar', code: 'ar-QA' },
  { name: 'Arabic, Saudi Arabia', code: 'ar-SA' },
  { name: 'Arabic, Syria', code: 'ar-SY' },
  { name: 'Arabic, Tunisia', code: 'ar-TN' },
  { name: 'Arabic, U.A.E.', code: 'ar-AE' },
  { name: 'Arabic, Yemen', code: 'ar-YE' },
  { name: 'Armenian', code: 'hy' },
  { name: 'Armenian, Armenia', code: 'hy-AM' },
  { name: 'Azeri, Cyrillic, Azerbaijan', code: 'az-Cyrl-AZ' },
  { name: 'Azeri, Latin', code: 'az' },
  { name: 'Azeri, Latin, Azerbaijan', code: 'az-AZ' },
  { name: 'Basque', code: 'eu' },
  { name: 'Basque, Spain', code: 'eu-ES' },
  { name: 'Belarusian', code: 'be' },
  { name: 'Belarusian, Belarus', code: 'be-BY' },
  { name: 'Bosnian, Bosnia and Herzegovina', code: 'bs-BA' },
  { name: 'Bulgarian', code: 'bg' },
  { name: 'Bulgarian, Bulgaria', code: 'bg-BG' },
  { name: 'Catalan', code: 'ca' },
  { name: 'Catalan, Spain', code: 'ca-ES' },
  { name: 'Chinese', code: 'zh' },
  { name: 'Chinese, Hong Kong', code: 'zh-HK' },
  { name: 'Chinese, Macau', code: 'zh-MO' },
  { name: 'Chinese, S', code: 'zh-CN' },
  { name: 'Chinese, Singapore', code: 'zh-SG' },
  { name: 'Chinese, T', code: 'zh-TW' },
  { name: 'Chinese-Cantonese, China', code: 'yue-CN' },
  { name: 'Chinese-Cantonese, Hong Kong', code: 'yue-HK' },
  { name: 'Chinese-Mandarin, China', code: 'cmn-CN' },
  { name: 'Croatian', code: 'hr' },
  { name: 'Croatian, Bosnia and Herzegovina', code: 'hr-BA' },
  { name: 'Croatian, Croatia', code: 'hr-HR' },
  { name: 'Czech', code: 'cs' },
  { name: 'Czech, Czech Republic', code: 'cs-CZ' },
  { name: 'Danish', code: 'da' },
  { name: 'Danish, Denmark', code: 'da-DK' },
  { name: 'Divehi', code: 'dv' },
  { name: 'Divehi, Maldives', code: 'dv-MV' },
  { name: 'Dutch', code: 'nl' },
  { name: 'Dutch, Belgium', code: 'nl-BE' },
  { name: 'Dutch, Netherlands', code: 'nl-NL' },
  { name: 'English', code: 'en' },
  { name: 'English, Australia', code: 'en-AU' },
  { name: 'English, Belize', code: 'en-BZ' },
  { name: 'English, Canada', code: 'en-CA' },
  { name: 'English, Caribbean', code: 'en-CB' },
  { name: 'English, Ireland', code: 'en-IE' },
  { name: 'English, Jamaica', code: 'en-JM' },
  { name: 'English, New Zealand', code: 'en-NZ' },
  { name: 'English, Republic of the Philippines', code: 'en-PH' },
  { name: 'English, South Africa', code: 'en-ZA' },
  { name: 'English, Trinidad and Tobago', code: 'en-TT' },
  { name: 'English, United Kingdom', code: 'en-GB' },
  { name: 'English, United States', code: 'en-US' },
  { name: 'English, Welsh', code: 'en-GB-WLS' },
  { name: 'English, Zimbabwe', code: 'en-ZW' },
  { name: 'Esperanto', code: 'eo' },
  { name: 'Estonian', code: 'et' },
  { name: 'Estonian, Estonia', code: 'et-EE' },
  { name: 'FYRO Macedonian', code: 'mk' },
  { name: 'FYRO Macedonian, Former Yugoslav Republic of Macedonia', code: 'mk-MK' },
  { name: 'Faroese', code: 'fo' },
  { name: 'Faroese, Faroe Islands', code: 'fo-FO' },
  { name: 'Farsi', code: 'fa' },
  { name: 'Farsi, Iran', code: 'fa-IR' },
  { name: 'Finnish', code: 'fi' },
  { name: 'Finnish, Finland', code: 'fi-FI' },
  { name: 'French', code: 'fr' },
  { name: 'French, Belgium', code: 'fr-BE' },
  { name: 'French, Canada', code: 'fr-CA' },
  { name: 'French, France', code: 'fr-FR' },
  { name: 'French, Luxembourg', code: 'fr-LU' },
  { name: 'French, Principality of Monaco', code: 'fr-MC' },
  { name: 'French, Switzerland', code: 'fr-CH' },
  { name: 'Galician', code: 'gl' },
  { name: 'Galician, Spain', code: 'gl-ES' },
  { name: 'Georgian', code: 'ka' },
  { name: 'Georgian, Georgia', code: 'ka-GE' },
  { name: 'German', code: 'de' },
  { name: 'German, Austria', code: 'de-AT' },
  { name: 'German, Germany', code: 'de-DE' },
  { name: 'German, Liechtenstein', code: 'de-LI' },
  { name: 'German, Luxembourg', code: 'de-LU' },
  { name: 'German, Switzerland', code: 'de-CH' },
  { name: 'Greek', code: 'el' },
  { name: 'Greek, Greece', code: 'el-GR' },
  { name: 'Gujarati', code: 'gu' },
  { name: 'Gujarati, India', code: 'gu-IN' },
  { name: 'Hebrew', code: 'he' },
  { name: 'Hebrew, Israel', code: 'he-IL' },
  { name: 'Hindi', code: 'hi' },
  { name: 'Hindi, India', code: 'hi-IN' },
  { name: 'Hungarian', code: 'hu' },
  { name: 'Hungarian, Hungary', code: 'hu-HU' },
  { name: 'Icelandic', code: 'is' },
  { name: 'Icelandic, Iceland', code: 'is-IS' },
  { name: 'Indonesian', code: 'id' },
  { name: 'Indonesian, Indonesia', code: 'id-ID' },
  { name: 'Italian', code: 'it' },
  { name: 'Italian, Italy', code: 'it-IT' },
  { name: 'Italian, Switzerland', code: 'it-CH' },
  { name: 'Japanese', code: 'ja' },
  { name: 'Japanese, Japan', code: 'ja-JP' },
  { name: 'Kannada', code: 'kn' },
  { name: 'Kannada, India', code: 'kn-IN' },
  { name: 'Kazakh', code: 'kk' },
  { name: 'Kazakh, Kazakhstan', code: 'kk-KZ' },
  { name: 'Konkani', code: 'kok' },
  { name: 'Konkani, India', code: 'kok-IN' },
  { name: 'Korean', code: 'ko' },
  { name: 'Korean, Korea', code: 'ko-KR' },
  { name: 'Kyrgyz', code: 'ky' },
  { name: 'Kyrgyz, Kyrgyzstan', code: 'ky-KG' },
  { name: 'Latvian', code: 'lv' },
  { name: 'Latvian, Latvia', code: 'lv-LV' },
  { name: 'Lithuanian', code: 'lt' },
  { name: 'Lithuanian, Lithuania', code: 'lt-LT' },
  { name: 'Malay', code: 'ms' },
  { name: 'Malay, Brunei Darussalam', code: 'ms-BN' },
  { name: 'Malay, Malaysia', code: 'ms-MY' },
  { name: 'Maltese', code: 'mt' },
  { name: 'Maltese, Malta', code: 'mt-MT' },
  { name: 'Maori', code: 'mi' },
  { name: 'Maori, New Zealand', code: 'mi-NZ' },
  { name: 'Marathi', code: 'mr' },
  { name: 'Marathi, India', code: 'mr-IN' },
  { name: 'Mongolian', code: 'mn' },
  { name: 'Mongolian, Mongolia', code: 'mn-MN' },
  { name: 'Northern Sotho', code: 'ns' },
  { name: 'Northern Sotho, South Africa', code: 'ns-ZA' },
  { name: 'Norwegian, Bokm?l', code: 'nb' },
  { name: 'Norwegian, Bokm?l, Norway', code: 'nb-NO' },
  { name: 'Norwegian, Nynorsk, Norway', code: 'nn-NO' },
  { name: 'Pashto', code: 'ps' },
  { name: 'Pashto, Afghanistan', code: 'ps-AR' },
  { name: 'Polish', code: 'pl' },
  { name: 'Polish, Poland', code: 'pl-PL' },
  { name: 'Portuguese', code: 'pt' },
  { name: 'Portuguese, Brazil', code: 'pt-BR' },
  { name: 'Portuguese, Portugal', code: 'pt-PT' },
  { name: 'Punjabi', code: 'pa' },
  { name: 'Punjabi, India', code: 'pa-IN' },
  { name: 'Quechua', code: 'qu' },
  { name: 'Quechua, Bolivia', code: 'qu-BO' },
  { name: 'Quechua, Ecuador', code: 'qu-EC' },
  { name: 'Quechua, Peru', code: 'qu-PE' },
  { name: 'Romanian', code: 'ro' },
  { name: 'Romanian, Romania', code: 'ro-RO' },
  { name: 'Russian', code: 'ru' },
  { name: 'Russian, Russia', code: 'ru-RU' },
  { name: 'Sami', code: 'se' },
  { name: 'Sami, Finland', code: 'se-FI' },
  { name: 'Sami, Norway', code: 'se-NO' },
  { name: 'Sami, Sweden', code: 'se-SE' },
  { name: 'Sanskrit', code: 'sa' },
  { name: 'Sanskrit, India', code: 'sa-IN' },
  { name: 'Serbian, Cyrillic, Bosnia and Herzegovina', code: 'sr-Cyrl-BA' },
  { name: 'Serbian, Cyrillic, Serbia and Montenegro', code: 'sr-Cyrl-SP' },
  { name: 'Serbian, Latin, Bosnia and Herzegovina', code: 'sr-BA' },
  { name: 'Serbian, Latin, Serbia and Montenegro', code: 'sr-SP' },
  { name: 'Slovak', code: 'sk' },
  { name: 'Slovak, Slovakia', code: 'sk-SK' },
  { name: 'Slovenian', code: 'sl' },
  { name: 'Slovenian, Slovenia', code: 'sl-SI' },
  { name: 'Spanish', code: 'es' },
  { name: 'Spanish, Argentina', code: 'es-AR' },
  { name: 'Spanish, Bolivia', code: 'es-BO' },
  { name: 'Spanish, Chile', code: 'es-CL' },
  { name: 'Spanish, Colombia', code: 'es-CO' },
  { name: 'Spanish, Costa Rica', code: 'es-CR' },
  { name: 'Spanish, Dominican Republic', code: 'es-DO' },
  { name: 'Spanish, Ecuador', code: 'es-EC' },
  { name: 'Spanish, El Salvador', code: 'es-SV' },
  { name: 'Spanish, Guatemala', code: 'es-GT' },
  { name: 'Spanish, Honduras', code: 'es-HN' },
  { name: 'Spanish, Mexico', code: 'es-MX' },
  { name: 'Spanish, Nicaragua', code: 'es-NI' },
  { name: 'Spanish, Panama', code: 'es-PA' },
  { name: 'Spanish, Paraguay', code: 'es-PY' },
  { name: 'Spanish, Peru', code: 'es-PE' },
  { name: 'Spanish, Puerto Rico', code: 'es-PR' },
  { name: 'Spanish, Spain', code: 'es-ES' },
  { name: 'Spanish, United States', code: 'es-US' },
  { name: 'Spanish, Uruguay', code: 'es-UY' },
  { name: 'Spanish, Venezuela', code: 'es-VE' },
  { name: 'Swahili', code: 'sw' },
  { name: 'Swahili, Kenya', code: 'sw-KE' },
  { name: 'Swedish', code: 'sv' },
  { name: 'Swedish, Finland', code: 'sv-FI' },
  { name: 'Swedish, Sweden', code: 'sv-SE' },
  { name: 'Syriac', code: 'syr' },
  { name: 'Syriac, Syria', code: 'syr-SY' },
  { name: 'Tagalog', code: 'tl' },
  { name: 'Tagalog, Philippines', code: 'tl-PH' },
  { name: 'Tamil', code: 'ta' },
  { name: 'Tamil, India', code: 'ta-IN' },
  { name: 'Tatar', code: 'tt' },
  { name: 'Tatar, Russia', code: 'tt-RU' },
  { name: 'Telugu', code: 'te' },
  { name: 'Telugu, India', code: 'te-IN' },
  { name: 'Thai', code: 'th' },
  { name: 'Thai, Thailand', code: 'th-TH' },
  { name: 'Tsonga', code: 'ts' },
  { name: 'Tswana', code: 'tn' },
  { name: 'Tswana, South Africa', code: 'tn-ZA' },
  { name: 'Turkish', code: 'tr' },
  { name: 'Turkish, Turkey', code: 'tr-TR' },
  { name: 'Ukrainian', code: 'uk' },
  { name: 'Ukrainian, Ukraine', code: 'uk-UA' },
  { name: 'Urdu', code: 'ur' },
  { name: 'Urdu, Islamic Republic of Pakistan', code: 'ur-PK' },
  { name: 'Uzbek, Cyrillic, Uzbekistan', code: 'uz-Cyrl-UZ' },
  { name: 'Uzbek, Latin', code: 'uz' },
  { name: 'Uzbek, Latin, Uzbekistan', code: 'uz-UZ' },
  { name: 'Vietnamese', code: 'vi' },
  { name: 'Vietnamese, Viet Nam', code: 'vi-VN' },
  { name: 'Welsh', code: 'cy' },
  { name: 'Welsh, United Kingdom', code: 'cy-GB' },
  { name: 'Xhosa', code: 'xh' },
  { name: 'Xhosa, South Africa', code: 'xh-ZA' },
  { name: 'Zulu', code: 'zu' },
  { name: 'Zulu, South Africa', code: 'zu-ZA' },
];
