import { ApiClientBase } from './ApiClientBase';
import { HourApi, HoursSelection, HoursVm } from '@voicefoundry-cloud/vf-omp-shared';
import { getLogger } from '@vf/services/LoggingService';

export class HoursApiClient extends ApiClientBase implements HourApi {
  constructor(apiUrl: string, useAuth: boolean) {
    super(apiUrl, getLogger('HoursApiClient'), useAuth);
  }

  async deleteHour(id: string): Promise<void> {
    await this.delete(`hours/${id}`);
  }

  async getAllHours(): Promise<HoursVm[]> {
    const result = await this.get('hours');
    return (await result.json()).sort((a, b) => a.name.localeCompare(b.name));
  }
  async getAvailableHoursSelections(): Promise<HoursSelection[]> {
    const result = await this.get('hours-available-selections');
    return await result.json();
  }

  async getHour(hourId: string): Promise<HoursVm> {
    const result = await this.get(`hours/${hourId}`);
    return await result.json();
  }

  async postHour(hour: HoursVm): Promise<HoursVm> {
    const result = await this.post('hours', hour);
    return await result.json();
  }

  async putHour(hour: HoursVm): Promise<HoursVm> {
    delete hour.permissions;
    const result = await this.put('hours', hour);
    return await result.json();
  }
}
