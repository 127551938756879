// import { ICtrManagementApi } from '@voicefoundry-cloud/vf-omp-shared/src/model/ctrManagement';
import { ApiClientBase } from './ApiClientBase';
import { getLogger } from '@vf/services/LoggingService';
import {
  PermissionsV2Promise,
  PermissionsV2ApiReturn,
  PermissionsV2TableUserObject,
  PermissionsV2ApiCreateUserBody,
  PermissionsV2TablePolicyObject,
  PermissionsV2ApiCreatePolicyBody,
  PermissionsV2TablePolicyGroupObject,
  PermissionsV2ApiFullPolicyGroupObject,
  PermissionsV2ApiCreatePolicyGroupBody,
  PermissionsV2TablePgPolicyReference,
  PermissionsV2TablePgUserReference,
  PermissionsV2ApiAssociateBody,
} from '@voicefoundry-cloud/vf-omp-shared';

export interface IPermissionsV2Api {
  //  '/api/v2/permissions/users'
  getUserByUsername: (body: { username: string }) => PermissionsV2Promise<PermissionsV2TableUserObject>;
  getAllUserObjects: () => PermissionsV2Promise<PermissionsV2TableUserObject[]>;
  upsertUser: (body: PermissionsV2ApiCreateUserBody) => PermissionsV2Promise<PermissionsV2TableUserObject>;
  deleteUser: (body: { username: string }) => PermissionsV2Promise<PermissionsV2TableUserObject>;
  //  '/api/v2/permissions/policies'
  getPolicyObjectsForUser: (body: { username: string }) => PermissionsV2Promise<PermissionsV2TablePolicyObject[]>;
  getAllPolicyObjects: () => PermissionsV2Promise<PermissionsV2TablePolicyObject[]>;
  upsertPolicy: (body: PermissionsV2ApiCreatePolicyBody) => PermissionsV2Promise<PermissionsV2TablePolicyObject>;
  deletePolicy: (body: { policyId: string }) => PermissionsV2Promise<PermissionsV2TablePolicyObject>;
  // '/api/v2/permissions/policy-groups'
  getPolicyGroupList: (body: {
    policyGroupIds?: string[];
  }) => PermissionsV2Promise<PermissionsV2TablePolicyGroupObject[]>;
  getFullPolicyGroupData: (body: {
    policyGroupIds?: string[];
  }) => PermissionsV2Promise<PermissionsV2ApiFullPolicyGroupObject[]>;
  upsertPolicyGroupObject: (
    body: PermissionsV2ApiCreatePolicyGroupBody
  ) => PermissionsV2Promise<PermissionsV2TablePolicyGroupObject>;
  deletePolicyGroup: (body: { policyGroupId: string }) => PermissionsV2Promise<PermissionsV2TablePolicyGroupObject>;
  // '/api/v2/permissions/association'
  requestPolicyGroupAssociation: (
    body: PermissionsV2ApiAssociateBody
  ) => PermissionsV2Promise<PermissionsV2TablePgPolicyReference | PermissionsV2TablePgUserReference>;
}

export class PermissionsV2ApiClient extends ApiClientBase implements IPermissionsV2Api {
  constructor(apiUrl: string, useAuth: boolean) {
    super(apiUrl, getLogger('CtrManagementApiClient'), useAuth);
  }
  //  '/api/v2/permissions/users'
  async getUserByUsername({ username }: { username: string }) {
    const result = await this.get(`/api/v2/permissions/users?username=${username}`);
    return (await result.json()) as PermissionsV2ApiReturn<PermissionsV2TableUserObject>;
  }
  async getAllUserObjects() {
    const result = await this.get(`/api/v2/permissions/users`);
    return (await result.json()) as PermissionsV2ApiReturn<PermissionsV2TableUserObject[]>;
  }
  async upsertUser(body: PermissionsV2ApiCreateUserBody) {
    const result = await this.put(`/api/v2/permissions/users`, body);
    return (await result.json()) as PermissionsV2ApiReturn<PermissionsV2TableUserObject>;
  }
  async deleteUser({ username }: { username: string }) {
    const result = await this.delete(`/api/v2/permissions/users?username=${username}`);
    return (await result.json()) as PermissionsV2ApiReturn<PermissionsV2TableUserObject>;
  }
  //  '/api/v2/permissions/policies'
  async getAllPolicyObjects() {
    const result = await this.get('/api/v2/permissions/policies');
    return (await result.json()) as PermissionsV2ApiReturn<PermissionsV2TablePolicyObject[]>;
  }
  async getPolicyObjectsForUser({ username }: { username: string }) {
    const result = await this.get(`/api/v2/permissions/policies?username=${username}`);
    return (await result.json()) as PermissionsV2ApiReturn<PermissionsV2TablePolicyObject[]>;
  }
  async upsertPolicy(body: PermissionsV2ApiCreatePolicyBody) {
    const result = await this.put(`/api/v2/permissions/policies`, body);
    return (await result.json()) as PermissionsV2ApiReturn<PermissionsV2TablePolicyObject>;
  }
  async deletePolicy({ policyId }: { policyId: string }) {
    const result = await this.delete(`/api/v2/permissions/policies?policyId=${policyId}`);
    return (await result.json()) as PermissionsV2ApiReturn<PermissionsV2TablePolicyObject>;
  }
  // '/api/v2/permissions/policy-groups'
  async getPolicyGroupList({ policyGroupIds = [] }: { policyGroupIds?: string[] }) {
    const result = await this.get(
      `/api/v2/permissions/policy-groups?listOnly=true&${
        policyGroupIds.length ? 'policyGroupIds=' + policyGroupIds.join(',') : ''
      }`
    );
    return (await result.json()) as PermissionsV2ApiReturn<PermissionsV2TablePolicyGroupObject[]>;
  }
  async getFullPolicyGroupData({ policyGroupIds = [] }: { policyGroupIds?: string[] }) {
    const result = await this.get(
      `/api/v2/permissions/policy-groups${policyGroupIds.length ? '?policyGroupIds=' + policyGroupIds.join(',') : ''}`,
      15000
    );
    return (await result.json()) as PermissionsV2ApiReturn<PermissionsV2ApiFullPolicyGroupObject[]>;
  }
  async upsertPolicyGroupObject(body: PermissionsV2ApiCreatePolicyGroupBody) {
    const result = await this.put(`/api/v2/permissions/policy-groups`, body);
    return (await result.json()) as PermissionsV2ApiReturn<PermissionsV2TablePolicyGroupObject>;
  }
  async deletePolicyGroup({ policyGroupId }: { policyGroupId: string }) {
    const result = await this.delete(`/api/v2/permissions/policy-groups?policyGroupId=${policyGroupId}`);
    return (await result.json()) as PermissionsV2ApiReturn<PermissionsV2TablePolicyGroupObject>;
  }
  // `/api/v2/permissions/association`
  async requestPolicyGroupAssociation(body: PermissionsV2ApiAssociateBody) {
    const result = await this.put(`/api/v2/permissions/association`, body, 15000);
    return (await result.json()) as PermissionsV2ApiReturn<
      PermissionsV2TablePgPolicyReference | PermissionsV2TablePgUserReference
    >;
  }
}
