export const VF_DEFAULT_QUEUE_FLOW_NAME_REGEX = new RegExp(/^VF_DEFAULT_QFE.*/);

/**
 * @function splitAndCapitalize - splits camel or pascal case string and capitalizes first letter of each word
 * @param camelOrPascalString - camel or pascal case string
 * @returns space separated words with first letter of each word capitalized
 */
export const splitAndCapitalize = (camelOrPascalString: string): string => {
  const words = camelOrPascalString.replace(/([A-Z])/g, ' $1').split(' ');
  words[0] = words[0].charAt(0).toUpperCase() + words[0].slice(1);
  return words.join(' ');
};
