import { ApiClientBase } from './ApiClientBase';
import { getLogger } from '@vf/services/LoggingService';
import { Voice } from '@aws-sdk/client-polly';
import {
  IConfigSetsApi,
  ConfigSetVm,
  GetPromptAudioRequest,
  PromptVm,
  AttributeVm,
  ConfigurationSetIE,
  IMappingSyncData,
} from '@voicefoundry-cloud/vf-omp-shared';

/**
 * @param apiUrl - should be suffixed with '/api/ or /api/v2/'
 */
export class ConfigSetApiClient extends ApiClientBase implements IConfigSetsApi {
  pollyVoicesPerLang = new Map<string, Voice[]>();
  // apiUrl is will be suffixed with '/api/' or '/api/v2/' depending on the feature flag 'apiV2'
  // There's no need to prefix urls in this client with '/api/' or '/api/v2/';
  constructor(apiUrl: string, useAuth: boolean) {
    super(apiUrl, getLogger('ConfigSetApiClient'), useAuth);
    console.log('ConfigSetApiClient apiUrl: ', apiUrl);
    //pre-populate Polly voices for English and Spanish
    this.getListOfPollyVoices('en-US');
    this.getListOfPollyVoices('es-US');
  }

  async deleteAttribute(configSetId: string, attrId: string): Promise<void> {
    await this.delete(`config-sets/${configSetId}/attributes/${attrId}`);
  }

  async deleteConfigSet(configSetId: string): Promise<void> {
    await this.delete(`config-sets/${configSetId}`);
  }

  async deletePrompt(configSetId: string, promptId: string): Promise<void> {
    await this.delete(`config-sets/${configSetId}/prompts/${promptId}`);
  }

  async getConfigSets(): Promise<ConfigSetVm[]> {
    const result = await this.get('config-sets');
    return (await result.json()).sort((a, b) => a.name.localeCompare(b.name));
  }

  async getConfigSet(configSetId: string): Promise<ConfigSetVm> {
    const result = await this.get(`config-sets/${configSetId}`);
    return await result.json();
  }

  async getPromptAudio(body: GetPromptAudioRequest): Promise<{ Audio: Buffer }> {
    const result = await this.post('config-sets/getPromptAudio', body);
    const resultConv = await result.json();
    return resultConv;
  }

  async getListOfPollyVoices(lang: string): Promise<Voice[]> {
    if (!this.pollyVoicesPerLang.has(lang)) {
      const result = await this.get(`config-sets/getPollyVoices/${lang}`);
      const resultJson = await result.json();
      this.pollyVoicesPerLang.set(lang, resultJson);
    }
    return this.pollyVoicesPerLang.get(lang) || [];
  }

  async createAttribute(attr: AttributeVm, configSetId: string, newConfigSet: boolean = false): Promise<AttributeVm> {
    const result = await this.post(
      `config-sets/${configSetId}/attributes${newConfigSet ? '?newConfigSet=true' : ''}`,
      attr
    );
    return await result.json();
  }

  async postPrompt(prompt: PromptVm, configSetId: string, newConfigSet: boolean = false): Promise<PromptVm> {
    const result = await this.post(
      `config-sets/${configSetId}/prompts${newConfigSet ? '?newConfigSet=true' : ''}`,
      prompt
    );
    return await result.json();
  }

  async updateAttribute(attr: AttributeVm, configSetId: string): Promise<AttributeVm> {
    const result = await this.put(`config-sets/${configSetId}/attributes`, attr);
    return await result.json();
  }

  async putPrompt(prompt: PromptVm, configSetId: string): Promise<PromptVm> {
    const result = await this.put(`config-sets/${configSetId}/prompts`, prompt);
    return await result.json();
  }
  async importConfigurationSetData(data: ConfigurationSetIE[]): Promise<any> {
    const result = await this.post(`config-sets/import/config-set-data`, data);
    return await result.json();
  }
  async importMappingSyncData(data: IMappingSyncData): Promise<IMappingSyncData> {
    const result = await this.post(`sync/import-mappings-sync-data`, data);
    return await result.json();
  }
  async exportLocalMappingSyncData(): Promise<IMappingSyncData> {
    const result = await this.get(`sync/export-local-mappings-sync-data`);
    return await result.json();
  }
  async getMappingSyncData(): Promise<IMappingSyncData> {
    const result = await this.get(`sync/mappings-sync-data`);
    return await result.json();
  }
}
