import {
  ListStringConditionOperators,
  ListNameConditionOperators,
  OmpPolicyConstructorType,
  SimpleStringConditionOperators,
} from './aggregatePermissionTypes';

export type OmpConnectService = 'connect';

export type ConnectActions =
  | `${OmpConnectService}:ListUsers`
  | `${OmpConnectService}:CreateUser`
  | `${OmpConnectService}:ListRoutingProfiles`
  | `${OmpConnectService}:ListQueues`
  | `${OmpConnectService}:ListPhoneNumbers`
  | `${OmpConnectService}:ListHierarhies`
  | `${OmpConnectService}:ListContactFlows`
  | `${OmpConnectService}:ListContactFlowModules`
  | `${OmpConnectService}:ListPrompts`;

export type ConnectUserConditionOperators =
  | 'UsersWithRoutingProfileId'
  | 'UsersWithHierarchyGroupId'
  | 'UsersWithoutHierarchyGroupId'
  | 'UsersWithoutRoutingProfileId'
  | 'UsersWithSecurityProfileId'
  | 'UsersWithoutSecurityProfileId'
  | ListNameConditionOperators;
export type OmpConnectListUsersPolicyType = OmpPolicyConstructorType<
  'Allow',
  'connect:ListUsers',
  ConnectUserConditionOperators
>;
export type OmpConnectPhoneNumbersPolicyType = OmpPolicyConstructorType<
  'Allow',
  'connect:ListPhoneNumbers',
  SimpleStringConditionOperators | ListStringConditionOperators
>;

export const connectActions: ConnectActions[] = [
  'connect:CreateUser',
  'connect:ListUsers',
  'connect:ListRoutingProfiles',
  'connect:ListQueues',
  'connect:ListPhoneNumbers',
  'connect:ListHierarhies',
  'connect:ListContactFlows',
  'connect:ListContactFlowModules',
];
