import { ContactFlowSummary, PhoneNumberSummary, PromptSummary } from '@aws-sdk/client-connect';
import {
  ConnectQueue,
  HoursSelection,
  MenuList,
  QueueFlowMapper,
  QueueFlowData,
} from '@voicefoundry-cloud/vf-omp-shared';
import { IMessage } from 'types/models/IMessage';
import { UpsertActionsType } from 'contexts/menuManagement/MenuManagementContext';

export interface QueueTreatmentUpsertHelpers {
  upsertAction: UpsertActionsType;
  openBackdrop: boolean;
  loadingMessage: string;
  errorMessage: string;
  warningMessage: string;
  successMessage: string;
  infoMessage: string;
}

export const initialUpserHelpersState: QueueTreatmentUpsertHelpers = {
  upsertAction: undefined,
  openBackdrop: false,
  loadingMessage: '',
  errorMessage: '',
  successMessage: '',
  warningMessage: '',
  infoMessage: '',
};

export const initialWorkingQueueTreatment: QueueFlowData = {
  id: 'queue-flow',
  key: '',
  lang: '',
  queueFlowId: '',
  description: '',
  isEnabled: false,
  slotOneOffer: 'NONE',
  slotTwoOffer: 'NONE',
  slotThreeOffer: 'NONE',
  slotFourOffer: 'NONE',
  preDisconnectOffer: 'NONE',
  maxLoops: '10',
  mailboxExtSource: 'WORKING_QUEUE_NAME',
  mailboxExtension: '',
  voicemailCallbackErrorAction: 'CONTINUE_HOLDING',
};

export const initialState: QueueTreatmentStateType = {
  availableHours: [],
  availableHoursLoaded: false,
  availableHoursLoading: false,
  queueTreatmentList: [],
  queueTreatmentLoaded: false,
  loadingQueueTreatments: false,
  deletingQueueTreatment: false,
  workingQueueTreatment: initialWorkingQueueTreatment,
  queues: [],
  queuesLoaded: false,
  flows: [],
  flowsLoaded: false,
  customerQueueFlows: [],
  menuList: [],
  menusLoaded: false,
  numbers: [],
  availableNumbers: [],
  numbersLoaded: false,
  availableCustomHours: [],
  prompts: [],
  promptsLoaded: false,
  loadingQueueFlowMapper: true,
  queueFlowMapperLoaded: false,
  queueFlowMapper: null,
  listLoaded: false,
  msg: undefined,
  interruptIntervals: [],
};

export default interface QueueTreatmentStateType {
  availableHours: HoursSelection[];
  availableHoursLoading: boolean;
  availableHoursLoaded: boolean;
  availableNumbers: PhoneNumberSummary[];
  numbersLoaded: boolean;
  workingQueueTreatment: QueueFlowData;
  queueTreatmentLoaded: boolean;
  queueTreatmentList: QueueFlowData[];
  listLoaded: boolean;
  deletingQueueTreatment: boolean;
  loadingQueueTreatments: boolean;
  queuesLoaded: boolean;
  flowsLoaded: boolean;
  promptsLoaded: boolean;
  queues: ConnectQueue[];
  flows: ContactFlowSummary[];
  prompts: PromptSummary[];
  customerQueueFlows: ContactFlowSummary[];
  menuList: MenuList;
  menusLoaded: boolean;
  numbers: PhoneNumberSummary[];
  availableCustomHours: HoursSelection[];
  msg: IMessage;
  queueFlowMapper: QueueFlowMapper;
  queueFlowMapperLoaded: boolean;
  loadingQueueFlowMapper: boolean;
  /**
   * Configured Interrupt intervals in seconds in mapping file
   */
  interruptIntervals: number[];
}
